import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.setOfEvaluateLevels,
})

export default {
  namespaced: true,
  state: {
    dataResoureces: [],
    totalRows: 0,
    evaluateLevels: [],
    statuses: STATUSES,
    dataResourecesExport: [],
  },
  getters: {
    dataResoureces: state => state.dataResoureces,
    totalRows: state => state.totalRows,
    evaluateLevels: state => state.evaluateLevels,
    statuses: state => state.statuses,
    dataResourecesExport: state => state.dataResourecesExport,
  },
  mutations: {
    SET_DATA_SOURCES: (state, data) => { state.dataResoureces = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
    SET_EVALUATE_LEVELS: (state, data) => { state.evaluateLevels = data },
    SET_DATA_SOURCES_EXPORT: (state, data) => { state.dataResourecesExport = data },
  },
  actions: {
    async getDataSources({ commit }, params) {
      try {
        const response = await axios.get(baseAttr.basePath, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createDataSource(commit, params) {
      try {
        const response = await axios.post(baseAttr.basePath, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateDataSource({ commit }, params) {
      try {
        const response = await axios.put(`${baseAttr.basePath}/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteDataSource({ commit }, id) {
      try {
        const response = await axios.delete(`${baseAttr.basePath}/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getEvaluateLevels({ commit }, id) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/${id}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_EVALUATE_LEVELS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async importSetOfEvaluateLevel({ commit }, params) {
      try {
          const response = await axios.post(`${baseAttr.basePath}/importSetOfEvaluateLevel`, { params })
          const { data } = response
          return data;
      } catch (e) {
          handleCatch(e)
      }
    },
    async getDataSourcesExport({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/exportSetOfEvaluateLevel`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DATA_SOURCES_EXPORT', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
