import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    revenuePlans: [],
    totalRows: 0,
    allTrainingSystems: [],
    allMajors: [],
    allRevenues: [],
    listCourses: [],
    listSemesters: [],
  },
  getters: {
    revenuePlans: state => state.revenuePlans,
    totalRows: state => state.totalRows,
    allTrainingSystems: state => state.allTrainingSystems,
    allMajors: state => state.allMajors,
    allRevenues: state => state.allRevenues,
    listCourses: state => state.listCourses,
    listSemesters: state => state.listSemesters,
  },
  mutations: {
    SET_REVENUEPLANS: (state, { total, records }) => {
      state.totalRows = total
      state.revenuePlans = records
    },

    SET_ALL: (state, { allTrainingSystems, allMajors, allRevenues }) => {
      state.allTrainingSystems = allTrainingSystems
      state.allMajors = allMajors
      state.allRevenues = allRevenues
    },
    SET_COURSES: (state, { data }) => {
      state.listCourses = data
    },
    SET_SEMESTERS: (state, { data }) => {
      state.listSemesters = data
    },
  },
  actions: {
    async begin({ commit }, params) {
      try {
        const responseTrainingSystems = await axios.get('uni/revenue_plan/training_systems')
        const responseMajors = await axios.get('uni/revenue_plan/majors')
        const responseRevenues = await axios.get('uni/revenue_plan/revenues', { params })

        if (responseTrainingSystems.data.code === ApiCode.SUCCESS && responseMajors.data.code === ApiCode.SUCCESS && responseRevenues.data.code === ApiCode.SUCCESS) {
          commit('SET_ALL', { allTrainingSystems: responseTrainingSystems.data.data.rs, allMajors: responseMajors.data.data.rs, allRevenues: responseRevenues.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getRevenuePlans({ commit }, params) {
      try {
        const response = await axios.get('uni/revenue_plan', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_REVENUEPLANS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createRevenuePlan({ commit }, params) {
      try {
        const response = await axios.post('uni/revenue_plan', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateRevenuePlan({ commit }, params) {
      try {
        const response = await axios.put(`uni/revenue_plan/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteRevenuePlan({ commit }, id) {
      try {
        const response = await axios.delete(`uni/revenue_plan/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCourses({ commit }, params) {
      try {
        const response = await axios.get('uni/revenue_plan/courses', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_COURSES', { data: response.data.data.rs })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getSemesters({ commit }, params) {
      try {
        const response = await axios.get('uni/revenue_plan/semesters', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_SEMESTERS', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
