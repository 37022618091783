import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    revenueSchedules: [],
    totalRows: 0,
    allTrainingSystems: [],
    allMajors: [],
    listCourses: [],
    listSemesters: [],
    statusRevenueSchedule: [],
  },
  getters: {
    revenueSchedules: state => state.revenueSchedules,
    totalRows: state => state.totalRows,
    allTrainingSystems: state => state.allTrainingSystems,
    allMajors: state => state.allMajors,
    listCourses: state => state.listCourses,
    listSemesters: state => state.listSemesters,
    statusRevenueSchedule: state => state.statusRevenueSchedule,
  },
  mutations: {
    SET_REVENUESCHEDULES: (state, { total, records }) => {
      state.totalRows = total
      state.revenueSchedules = records
    },

    SET_ALL: (state, { allTrainingSystems, allMajors }) => {
      state.allTrainingSystems = allTrainingSystems
      state.allMajors = allMajors
    },
    SET_COURSES: (state, { data }) => {
      state.listCourses = data
    },
    SET_SEMESTERS: (state, { data }) => {
      state.listSemesters = data
    },
    SET_STATUSREVENUESCHEDULE: (state, { data }) => {
      state.statusRevenueSchedule = data
    },
  },
  actions: {
    async begin({ commit }) {
      try {
        const responseTrainingSystems = await axios.get('uni/revenue_plan/training_systems')
        const responseMajors = await axios.get('uni/revenue_plan/majors')

        if (responseTrainingSystems.data.code === ApiCode.SUCCESS && responseMajors.data.code === ApiCode.SUCCESS) {
          commit('SET_ALL', { allTrainingSystems: responseTrainingSystems.data.data.rs, allMajors: responseMajors.data.data.rs })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getRevenueSchedules({ commit }, params) {
      try {
        const response = await axios.get('uni/revenue_schedule', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_REVENUESCHEDULES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createRevenueSchedule({ commit }, params) {
      try {
        const response = await axios.post('uni/revenue_schedule', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createRevenueScheduleKTK({ commit }, params) {
      try {
        const response = await axios.post('uni/revenue_schedule/create-ktk', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createAllRevenueSchedule({ commit }, params) {
      try {
        const response = await axios.post('uni/revenue_schedule/all', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateRevenueSchedule({ commit }, params) {
      try {
        const response = await axios.put(`uni/revenue_schedule/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteRevenueSchedule({ commit }, id) {
      try {
        const response = await axios.delete(`uni/revenue_schedule/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteRevenueKTKSchedule({ commit }, id) {
      try {
        const response = await axios.delete(`uni/revenue_schedule/delete-revenue-ktk-schedule/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCourses({ commit }, params) {
      try {
        const response = await axios.get('uni/revenue_plan/courses', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_COURSES', { data: response.data.data.rs })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getSemesters({ commit }, params) {
      try {
        const response = await axios.get('uni/revenue_plan/semesters', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_SEMESTERS', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCountRevenueSchedule({ commit }, params) {
      try {
        const response = await axios.get('uni/revenue_schedule/countRevenueSchedule', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_STATUSREVENUESCHEDULE', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
