import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    maxOrderNo: 0,
    generalScores: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    maxOrderNo: state => state.maxOrderNo,
    generalScores: state => state.generalScores,
  },
  mutations: {
    SET_LISTS: (state, data) => {
      state.dataLists = data
    },
    SET_MAX_ORDER_NO: (state, data) => {
      state.maxOrderNo = data
    },
    SET_GENERAL_SCORES: (state, data) => {
      state.generalScores = data
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/componentScores', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          commit('SET_LISTS', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
      async getDataByCreditClass({ commit }, params) {
          try {
              const response = await axios.get('uni/componentScores/get_by_credit_class', { params })
              const { data } = response
              if (data.code === ApiCode.SUCCESS) {
                  const mapData = data.data.map(item => ({
                      value: item.id,
                      label: item.name,
                  }))
                  return mapData
              }
              return [];
          } catch (e) {
              handleCatch(e)
          }
      },
    async createData(commit, params) {
      try {
        const response = await axios.post('uni/componentScores', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`uni/componentScores/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`uni/componentScores/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMaxOrderNo({ commit }, params) {
      try {
        const response = await axios.get('uni/componentScores/maxOrderNo', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_MAX_ORDER_NO', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getByScoringFormula({ commit }, params) {
      try {
        const response = await axios.get('uni/generalScores/getByScoringFormula', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_GENERAL_SCORES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
