import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    rooms: [],
    total: 0,
    statuses: [],
    roomSelection: [],
  },
  getters: {
    rooms: state => state.rooms,
    total: state => state.total,
    statuses: state => state.statuses,
    roomSelection: state => state.roomSelection,
  },
  mutations: {
    SET_ROOMS: (state, rooms) => { state.rooms = rooms },
    SET_TOTAL: (state, total) => { state.total = total },
    SET_STATUS: (state, statuses) => { state.statuses = statuses },
    SET_ROOM_SELECTION: (state, roomSelection) => { state.roomSelection = roomSelection },
  },

  actions: {

    async getRooms({ commit }, params) {
      try {
        const response = await axios.get('uni/rooms', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_ROOMS', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async createRoom({ commit }, dataObj) {
      try {
        const response = await axios.post('uni/rooms', dataObj)
        const { data } = response
        return data
      } catch (e) {
        handleCatch(e)
      }
    },

    async updateRoom({ commit }, dataObj) {
      try {
        const response = await axios.put('uni/rooms', dataObj)
        const { data } = response
        return data
      } catch (e) {
        handleCatch(e)
      }
    },

    async deleteRoom({ commit }, id) {
      try {
        const response = await axios.delete(`uni/rooms/${id}`)
        const { data } = response
        return data.code === ApiCode.SUCCESS
      } catch (e) {
        handleCatch(e)
      }
    },

    async getRoomsSelection({ commit }) {
      try {
        const response = await axios.get('uni/rooms/selection')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { rooms } = data.data
          commit('SET_ROOM_SELECTION', rooms)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

  },
}
