import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { ADMISSION_FORM_LOCKS, STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    admissionRounds: [],
    totalRows: 0,
    statuses: STATUSES,
    lockStatuses: ADMISSION_FORM_LOCKS,
    admissionRoundsByOrganization: [],
    admissionRoundById: [],
    percentAdmissionRound: 100,
  },
  getters: {
    admissionRounds: state => state.admissionRounds,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    lockStatuses: state => state.lockStatuses,
    admissionRoundsByOrganization: state => state.admissionRoundsByOrganization,
    admissionRoundById: state => state.admissionRoundById,
    percentAdmissionRound: state => state.percentAdmissionRound,
  },
  mutations: {
    SET_ADMISSION_ROUNDS: (state, { total, records }) => {
      state.totalRows = total
      state.admissionRounds = records
    },
    SET_ADMISSION_ROUNDS_BY_ORGANIZATION: (state, records) => { state.admissionRoundsByOrganization = records },
    SET_ADMISSION_ROUND_BY_ID: (state, data) => { state.admissionRoundById = data },
    SET_PERCENT_ADMISSION_ROUND: (state, data) => { state.percentAdmissionRound = data },
  },
  actions: {
    async readAdmissionRounds({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_round', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_ADMISSION_ROUNDS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async readAdmissionRoundsByOrganization({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_round/get_all_by_organization', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.records.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_ADMISSION_ROUNDS_BY_ORGANIZATION', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async readAdmissionRoundsById({ commit }, params) {
      try {
        const response = await axios.get(`/admissions/admission_round/${params.id}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_ADMISSION_ROUND_BY_ID', data.data.admissionRounds)
          commit('SET_PERCENT_ADMISSION_ROUND', data.data.calculatePercentAdmissionRound)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createAdmissionRounds({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_round', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateAdmissionRounds({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/admission_round/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteAdmissionRounds({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/admission_round/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
