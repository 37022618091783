import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/articles',
    name: 'articles',
    component: () => import('@/views/portal/articles/Articles.vue'),
    meta: {
      pageTitle: 'Quản lý bài viết',
      breadcrumb: [
        {
          text: 'Quản lý Portal',
          active: false,
        },
        {
          text: 'Danh sách bài viết',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.ARTICLES,
    },
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import('@/views/documents/index.vue'),
    meta: {
      pageTitle: 'Quản lý tài liệu',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Quản lý tài liệu',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.DOCUMENTS,
    },
  },
]
