import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/student-scholarship',
    name: 'studentScholarship',
    component: () => import('@/views/student-scholarship/StudentScholarship.vue'),
    meta: {
      pageTitle: 'Danh sách nhận học bổng',
      breadcrumb: [
        {
          text: 'Công tác Học sinh - Sinh viên',
          active: false,
        },
        {
          text: 'Danh sách nhận học bổng',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.STUDENTSCHOLARSHIP,
    },
  },
]
