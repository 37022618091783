import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    formulaAdmissionScores: [],
    totalRows: 0,
    statuses: STATUSES,
  },
  getters: {
    formulaAdmissionScores: state => state.formulaAdmissionScores,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
  },
  mutations: {
    SET_FORMULA_ADMISSION_SCORES: (state, { total, records }) => {
      state.totalRows = total
      state.formulaAdmissionScores = records
    },
  },
  actions: {
    async read({ commit }, params) {
      try {
        const response = await axios.get('/admissions/formula_admission_score', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_FORMULA_ADMISSION_SCORES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async create({ commit }, params) {
      try {
        const response = await axios.post('/admissions/formula_admission_score', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async update({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/formula_admission_score/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async delete({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/formula_admission_score/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
