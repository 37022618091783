import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    studentsPassCondition: [],
    totalRows: 0,
    credentialsStorage: [],
    classes: [],
    dataLists: [],
  },
  getters: {
    totalRows: state => state.totalRows,
    studentsPassCondition: state => state.studentsPassCondition,
    credentialsStorage: state => state.credentialsStorage,
    classes: state => state.classes,
    dataLists: state => state.dataLists,
  },
  mutations: {
    SET_TOTAL_ROWS: (state, data) => {
      state.totalRows = data
    },
    SET_STUDENTS_PASS_CONDITION: (state, data) => {
      state.studentsPassCondition = data
    },
    SET_CREDENTIALS_STORAGE: (state, data) => {
      state.credentialsStorage = data
    },
    SET_CLASSES: (state, data) => {
      state.classes = data
    },
    SET_DATA: (state, data) => {
      state.dataLists = data.records
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('certificates/graduations/graduation-class', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_DATA', {
            records: response.data.data,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getClasses({ commit }, params) {
      try {
        const response = await axios.get('certificates/classes', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const mapData = data.data.records.map(item => ({ value: item.id, label: item.className }))
          commit('SET_CLASSES', {
            data: mapData,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStStudentsPassCondition({ commit }, params) {
      try {
        const response = await axios.get('certificates/credential-create/student-pass-condition', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_STUDENTS_PASS_CONDITION', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createCredential({ commit }, params) {
      try {
        const response = await axios.post(`certificates/credential-create/generate-excel?sampleFileId=${params.sampleFileId}`, params.request, {
          responseType: 'blob',
        })
        return response
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async addCredentialsStorage({commit}, params) {
      try {
        const response = await axios.post('certificates/credentials-storage', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCredentialsStorageByInfoStudent({commit}, params) {
      try {
        const response = await axios.post('certificates/credentials-storage/getListCredentialStorage', params)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const records = data.data
          commit('SET_CREDENTIALS_STORAGE', records)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
