import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataSources: [],
    totalRows: 0,
    maxOrderNo: 0,
  },
  getters: {
    dataSources: state => state.dataSources,
    totalRows: state => state.totalRows,
    maxOrderNo: state => state.maxOrderNo,
  },
  mutations: {
    SET_DATA_SOURCES: (state, data) => { state.dataSources = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => { state.maxOrderNo = maxOrderNo },
  },
  actions: {
    async getDataSources({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_carousels', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async createDataSource({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_carousels', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async uploadCarousel({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_carousels/uploadCarousel', params)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
        return []
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async updateDataSource({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/admission_carousels/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async deleteDataSource({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/admission_carousels/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getMaxOrderNo({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_carousels/max/order-no', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { maxOrderNo } = data.data
          commit('SET_MAX_ORDER_NO', maxOrderNo)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getLinkAvatar({ commit }, link) {
      try {
        const response = await axios.get(`admissions/applicant_students/getFileMinioByPathFile?pathFile=${link}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, data: data.data }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
