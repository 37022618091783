import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async downloadPdfTBCToanKhoa({ commit }, params) {
      try {
        const responseStudentInfo = await axios({
          method: 'get',
          url: 'uni/reports/reportDiemTb',
          responseType: 'blob',
          params,
        })
        return responseStudentInfo.data
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadPdfDuDkThiLlct({ commit }, params) {
      try {
        const responseStudentInfo = await axios({
          method: 'get',
          url: 'uni/reports/reportDuDkThiLlct',
          responseType: 'blob',
          params,
        })
        return responseStudentInfo.data
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadPdfDuDkTn({ commit }, params) {
      try {
        const responseStudentInfo = await axios({
          method: 'get',
          url: 'uni/reports/reportDuDkTn',
          responseType: 'blob',
          params,
        })
        return responseStudentInfo.data
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadPdfDoTn({ commit }, params) {
      try {
        const responseStudentInfo = await axios({
          method: 'get',
          url: 'uni/reports/reportDoTn',
          responseType: 'blob',
          params,
        })
        return responseStudentInfo.data
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
