import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    teachers: [],
    totalRows: 0,
    statuses: STATUSES,
    departments: [],
  },
  getters: {
    teachers: state => state.teachers,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    departments: state => state.departments,
  },
  mutations: {
    SET_TEACHERS: (state, records) => {
      state.teachers = records
    },
    SET_TOTAL_ROWS: (state, totalRows) => {
      state.totalRows = totalRows
    },
  },
  actions: {
    async readTeachers({ commit }, params) {
      try {
        const response = await axios.get('uni/teachers', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_TEACHERS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createTeachers({ commit }, params) {
      try {
        const response = await axios.post('uni/teachers', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateTeachers({ commit }, params) {
      try {
        const response = await axios.put(`uni/teachers/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateTeachersByTeam({ commit }, params) {
      try {
        const response = await axios.put('uni/teachers/updateTeachersByTeam', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteTeachers({ commit }, id) {
      try {
        const response = await axios.delete(`uni/teachers/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getTeachersByCourseSemesterId({ commit }, params) {
      try {
        const response = await axios.get('uni/teachers/getByCourseSemesterId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TEACHERS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTeachersByOrganizationId({ commit }) {
      try {
        const response = await axios.get('uni/teachers/getByOrganizationId')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TEACHERS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadTeachers({ commit }, params) {
      try {
        const responseStudentInfo = await axios({
          method: 'get',
          url: 'uni/teachers/reportDanhSachGiangVien',
          responseType: 'blob',
          params,
        })
        return responseStudentInfo.data
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
