import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    credentialSampleConfigs: [],
    totalRows: 0,
  },
  getters: {
    credentialSampleConfigs: state => state.credentialSampleConfigs,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_CREDENTIAL_SAMPLE_CONFIGS(state, credentialSampleConfigs) {
      state.credentialSampleConfigs = credentialSampleConfigs
    },
    SET_TOTAL_ROWS(state, totalRows) {
      state.totalRows = totalRows
    },
  },
  actions: {
    async readCredentialSampleConfigs({commit}, params) {
      try {
        const response = await axios.get('certificates/credential_sample_configs', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_CREDENTIAL_SAMPLE_CONFIGS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createCredentialSampleConfig({ commit }, params) {
      try {
        const response = await axios.post('certificates/credential_sample_configs', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
