import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  actions: {
    async deleteCache() {
      try {
        const response = await axios.delete('uni/cache')
        const { data } = response
        return data.code === ApiCode.SUCCESS
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
