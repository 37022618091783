import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/diplomas',
    name: 'diploma',
    component: () => import('@/views/diploma/Diploma.vue'),
    meta: {
      pageTitle: 'Quản lý kế hoạch đăng ký song ngành',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: false,
        },
        {
          text: 'Đăng ký song ngành',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.DIPLOMA,
    },
  },
  {
    path: '/diplomas/student',
    name: 'diplomaStudents',
    component: () => import('@/views/student-dual-major/StudentDualMajor.vue'),
    meta: {
      pageTitle: 'Xét duyệt đăng ký song ngành',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: false,
        },
        {
          text: 'Đăng ký song ngành',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: 'STUDENT_DUAL_MAJOR',
    },
  },
]
