import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/student_examination_room',
    name: 'studentExaminationRoom',
    component: () => import('@/views/student-examination-room/StudentExaminationRoom.vue'),
    meta: {
      pageTitle: 'Danh sách phách sinh viên',
      breadcrumb: [
        {
          text: 'Quản lý thi',
          active: false,
        },
        {
          text: 'Danh sách sinh viên',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.STUDENT_EXAMINATION_ROOM,
    },
  },
  {
    path: '/examination/input-point-code/:id?',
    name: 'examinationInputPointByCode',
    component: () => import('@/views/student-examination-room/StudentExaminationPoint.vue'),
    meta: {
      pageTitle: 'Nhập điểm thi học phần theo phách',
      breadcrumb: [
        {
          text: 'Quản lý thi',
          active: false,
        },
        {
          text: 'Nhập điểm thi',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.EXAMINATION_POINT,
    },
  },
  {
    path: '/examination/input-point-manual/:id?',
    name: 'examinationInputPointManual',
    component: () => import('@/views/student-examination-room/StudentExaminationPointManual.vue'),
    meta: {
      pageTitle: 'Nhập điểm thi theo lớp độc lập',
      breadcrumb: [
        {
          text: 'Quản lý thi',
          active: false,
        },
        {
          text: 'Nhập điểm thi',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.MANUAL_EXAMINATION_POINT,
    },
  },
  {
    path: '/studentExaminationRoom/checkExaminationPaper',
    name: 'CheckExaminationPaper',
    component: () => import('@/views/student-examination-room/CheckExaminationPaper.vue'),
    meta: {
      pageTitle: 'Phúc khảo điểm thi',
      breadcrumb: [
        {
          text: 'Quản lý thi',
          active: false,
        },
        {
          text: 'Phúc khảo điểm thi',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CHECK_EXAMINATION_PAPER,
    },
  },
  {
    path: '/studentExaminationRoom/sync-point-lms',
    name: 'syncPointLms',
    component: () => import('@/views/student-examination-room/SyncPointLms.vue'),
    meta: {
      pageTitle: 'Đồng bộ điểm thi LMS',
      breadcrumb: [
        {
          text: 'Quản lý thi',
          active: false,
        },
        {
          text: 'Đồng bộ điểm thi LMS',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SYNC_POINT_LMS,
    },
  },
  {
    path: '/examination/studentBanned',
    name: 'studentBannedExamination',
    component: () => import('@/views/student-examination-room/StudentBannedExamination.vue'),
    meta: {
      pageTitle: 'Danh sách sinh viên không được thi',
      breadcrumb: [
        {
          text: 'Quản lý thi',
          active: false,
        },
        {
          text: 'Danh sách sinh viên không được thi',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.BAN_STUDENT_EXAM,
    },
  },
  {
    path: '/examination/graduation-project-score',
    name: 'graduationProjectScore',
    component: () => import('@/views/graduation-project-register-round/GraduationProjectScore.vue'),
    meta: {
      pageTitle: 'Nhập điểm khóa luận tốt nghiệp',
      breadcrumb: [
        {
          text: 'Điểm khóa luận tốt nghiệp',
          active: false,
        },
        {
          text: 'Nhập điểm khóa luận tốt nghiệp',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.GRADUATION_PROJECT_SCORE,
    },
  },
]
