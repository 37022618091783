import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/scoreLock/creditClass',
    name: 'ScoreLockCreditClass',
    component: () => import('@/views/score-lock/ScoreLockCreditClass.vue'),
    meta: {
      pageTitle: 'Khóa/mở điểm Lớp độc lập',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Khóa/mở điểm Lớp độc lập',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SCORE_LOCK_CREDIT_CLASS,
    },
  },
  {
    path: '/scoreLock/examinationRoom',
    name: 'ScoreLockExaminationRoom',
    component: () => import('@/views/score-lock/ScoreLockExaminationRoom.vue'),
    meta: {
      pageTitle: 'Khóa/mở điểm Phòng thi',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Khóa/mở điểm Phòng thi',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SCORE_LOCK_EXAMINATION_ROOM,
    },
  },
  {
    path: '/scoreLock/password',
    name: 'scoreLockPassword',
    component: () => import('@/views/score-lock/ScoreLockPassword.vue'),
    meta: {
      pageTitle: 'Mật khẩu khóa điểm',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Mật khẩu khóa điểm',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SCORE_LOCK_PASSWORD,
    },
  },
]
