import {ResourceCode } from '@/const/code'

export default [
    {
        path: '/digital-libraries/report-reader-detail',
        name: 'reportReaderDetail',
        component: () => import('@/views/digital-library/report-reader-details/Index.vue'),
        meta: {
            pageTitle: 'Chi tiết bạn đọc',
            resource: ResourceCode.DIGITAL_LIBRARY_REPORT_READER_DETAIL,
        },
    },
    {
        path: '/digital-libraries/report-number-of-users',
        name: 'reportNumberOfUsers',
        component: () => import('@/views/digital-library/report-number-of-user/Index'),
        meta: {
            pageTitle: 'Số lượng người dùng',
            resource: ResourceCode.DIGITAL_LIBRARY_REPORT_NUMBER_OF_USER,
        },
    },
    {
        path: '/digital-libraries/report-number-borrowings-and-returns',
        name: 'reportNumberBorrowingsAndRetuns',
        component: () => import('@/views/digital-library/report-count-borrowed-and-returned/Index.vue'),
        meta: {
            pageTitle: 'Số mượn trả',
            resource: ResourceCode.DIGITAL_LIBRARY_REPORT_COUNT_BORROWED_AND_RETURNED,
        },
    },
]
