import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    examinationRooms: [],
    totalRows: 0,
    allRoom: [],
    listAdmissionSubject: [],
    admissionForms: [],
    roomBySupervisor: [],
    totalRoomBySupervisor: 0,
  },
  getters: {
    examinationRooms: state => state.examinationRooms,
    totalRows: state => state.totalRows,
    allRoom: state => state.allRoom,
    listAdmissionSubject: state => state.listAdmissionSubject,
    admissionForms: state => state.admissionForms,
    roomBySupervisor: state => state.roomBySupervisor,
    totalRoomBySupervisor: state => state.totalRoomBySupervisor,
  },
  mutations: {
    SET_ROOMS: (state, data) => {
      state.allRoom = data
    },
    SET_ADMISSION_SUBJECT: (state, { data }) => {
      state.listAdmissionSubject = data
    },
    SET_ADMISSION_FORMS: (state, data) => {
      state.admissionForms = data
    },
    SET_EXAMINATION_ROOMS: (state, { total, records }) => {
      state.totalRows = total
      state.examinationRooms = records
    },
    SET_ROOM_BY_SUPERVISOR: (state, { total, records }) => {
      state.totalRoomBySupervisor = total
      state.roomBySupervisor = records
    },
  },
  actions: {
    async getAllRooms({ commit }, params) {
      try {
        const response = await axios.get('uni/examinationRooms/rooms', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_ROOMS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAdmissionExaminationRooms({ commit }, params) {
      try {
        const response = await axios.get('admissions/admission_examination_rooms', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_EXAMINATION_ROOMS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createAdmissionExaminationRoom({ commit }, params) {
      try {
        const response = await axios.post('admissions/admission_examination_rooms', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateAdmissionExaminationRoom({ commit }, params) {
      try {
        const response = await axios.put(`admissions/admission_examination_rooms/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteAdmissionExaminationRoom({ commit }, id) {
      try {
        const response = await axios.delete(`admissions/admission_examination_rooms/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async insertAdmissionExaminationRooms({ commit }, params) {
      try {
        const response = await axios.put('admissions/admission_examination_rooms', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createHeaderStudentExaminationRoom({ commit }, params) {
      try {
        const response = await axios.put('admissions/admission_examination_rooms/create_header', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createHeaderStudentExaminationRoomAgain({ commit }, params) {
      try {
        const response = await axios.put('admissions/admission_examination_rooms/create_header_again', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getAdmissionSubject({ commit }, params) {
      try {
        const response = await axios.get('admissions/admission_examination_rooms/admissionSubject', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_ADMISSION_SUBJECT', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async readAdmissionFormByAdmissionRoundId({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_form/getAdmissionFormByAdmissionRoundId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.records.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_ADMISSION_FORMS', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getScoreLockAdmissionExaminationRooms({ commit }, params) {
      try {
        const response = await axios.get('admissions/admission_examination_rooms/scoreLock', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_EXAMINATION_ROOMS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateScoreLockAdmissionExaminationRooms({ commit }, params) {
      try {
        const response = await axios.put('admissions/admission_examination_rooms/scoreLock', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async exportPdf({ commit }, params) {
      try {
        const response = await axios.get('admissions/reports/exportPdfStudentsExaminationRoom', { params, responseType: 'blob' })
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getRoomBySupervisor({ commit }, params) {
      try {
        const response = await axios.get('admissions/admission_examination_rooms/roomsBySupervisor', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_ROOM_BY_SUPERVISOR', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
