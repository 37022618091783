import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    subjects: [],
    totalRows: 0,
    statuses: [],
    subjectTypes: [],
    departments: [],
  },
  getters: {
    subjects: state => state.subjects,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    departments: state => state.departments,
    subjectTypes: state => state.subjectTypes,
  },
  mutations: {
    SET_SUBJECTS: (state, records) => {
      state.subjects = records
    },
    SET_TOTAL_ROWS: (state, total) => {
      state.totalRows = total
    },
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
    SET_DEPARTMENTS: (state, departments) => {
      state.departments = departments
    },
    SET_SUBJECT_TYPES: (state, subjectTypes) => {
      state.subjectTypes = subjectTypes
    },
  },
  actions: {
    async begin({ commit }, params) {
      try {
        const response = await axios.get('uni/subjects/begin', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { statuses, subjectTypes, departments } = data.data
          commit('SET_STATUSES', statuses)
          commit('SET_SUBJECT_TYPES', subjectTypes)
          commit('SET_DEPARTMENTS', departments)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async readSubjects({ commit }, params) {
      try {
        const response = await axios.get('uni/subjects', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_SUBJECTS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async readSubjectsByCourseSemesterAndStudent({ commit }, params) {
      try {
        const response = await axios.get('uni/subjects/subject_by_courseSemester_student', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_SUBJECTS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createSubjects({ commit }, params) {
      try {
        const response = await axios.post('uni/subjects', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateSubjects({ commit }, params) {
      try {
        const response = await axios.put(`uni/subjects/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteSubjects({ commit }, id) {
      try {
        const response = await axios.delete(`uni/subjects/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getSubjectsByCourseSemesterId({ commit }, params) {
      try {
        const response = await axios.get('uni/subjects/getByCourseSemesterId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_SUBJECTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getSubjectsByCourseSemesterIdAndTeacherId({ commit }, params) {
      try {
        const response = await axios.get('uni/subjects/getByCourseSemesterIdAndTeacherId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_SUBJECTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async importSubject({ commit }, params) {
      try {
        const response = await axios.post('uni/subjects/importSubject', { params })
        const { data } = response
        return data
      } catch (e) {
        handleCatch(e)
      }
    },

    async getSubjectByCode({ commit }, params) {
      try {
        const response = await axios.get('uni/subjects/get_subject_by_code', { params })
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
