export default [
  {
    path: '/curriculum-vitae/:id?',
    name: 'curriculumVitae',
    component: () => import('@/views/curriculum-vitae/CurriculumVitae.vue'),
    meta: {
      pageTitle: 'Sơ yếu lý lịch sinh viên',
      breadcrumb: [
        {
          text: 'Danh mục',
          active: false,
        },
        {
          text: 'Sơ yếu lý lịch',
          active: true,
        },
      ],
      action: 'READ',
      resource: 'CURRICULUMVITAE',
    },
  },
]
