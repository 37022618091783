import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/student-discipline',
    name: 'studentDiscipline',
    component: () => import('@/views/student-discipline/StudentDiscipline.vue'),
    meta: {
      pageTitle: 'Danh sách nhận kỷ luật',
      breadcrumb: [
        {
          text: 'Công tác Học sinh - Sinh viên',
          active: false,
        },
        {
          text: 'Danh sách nhận kỷ luật',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.STUDENTDISCIPLINE,
    },
  },
]
