import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    admissionConfirmationCertificateAccepts: [],
    totalRows: 0,
    statuses: STATUSES,
    dataAdmissionConfirmationCertificateAccepts: [],
    dataByCriteriaId: []
  },
  getters: {
    admissionConfirmationCertificateAccepts: state => state.admissionConfirmationCertificateAccepts,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    dataByCriteriaId: state => state.dataByCriteriaId,
    dataAdmissionConfirmationCertificateAccepts: state => state.dataAdmissionConfirmationCertificateAccepts,
  },
  mutations: {
    SET_ADMISSION_CONFIRMATION_CERTIFICATE_ACCEPTS: (state, { total, records }) => {
      state.totalRows = total
      state.admissionConfirmationCertificateAccepts = records
    },
    SET_DATA_BY_CRITERIA_ID: (state, dataByCriteriaId) => { state.dataByCriteriaId = dataByCriteriaId },
    SET_DATA_ADMISSION_CONFIRMATION_CERTIFICATE_ACCEPTS: (state, dataAdmissionConfirmationCertificateAccepts) => { state.dataAdmissionConfirmationCertificateAccepts = dataAdmissionConfirmationCertificateAccepts },
  },
  actions: {
    async getDataByCriteriaId({ commit }, params) {
      try {
        const response = await axios.get('admissions/admission_confirmation_certificate_accept/getByCriteriaSetId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DATA_BY_CRITERIA_ID', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async read({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_confirmation_certificate_accept', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_ADMISSION_CONFIRMATION_CERTIFICATE_ACCEPTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDataAdmissionConfirmationCertificateAccept({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_confirmation_certificate_accept/allActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_DATA_ADMISSION_CONFIRMATION_CERTIFICATE_ACCEPTS', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async create({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_confirmation_certificate_accept', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async update({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/admission_confirmation_certificate_accept/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async delete({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/admission_confirmation_certificate_accept/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
