import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    resourceName: {
      fullName: 'Nguyện vọng xét tuyển của thí sinh',
      shortName: 'Nguyện vọng xét tuyển',
    },
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    resourceName: state => state.resourceName,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('/admissions/aspiration_information/aspiration_information_accept', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_LISTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async filterAspirationInformation ({ commit }, params) {
      try {
        const response = await axios.put('/admissions/aspiration_information/filterAspirationInformation', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async acceptAspirationInformation ({ commit }, params) {
      try {
        const response = await axios.put('/admissions/aspiration_information/acceptAspirationInformation', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getDataAdmission({ commit }, params) {
      try {
        const response = await axios.get('/admissions/aspiration_information/aspiration_information_accepted', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_LISTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async resetApplicantStudentAcceptedResults({ commit }, admissionRoundId) {
      try {
        const response = await axios.delete(`admissions/aspiration_information/resetResults/${admissionRoundId}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
