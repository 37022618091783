import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    roles: [],
    totalRows: 0,
    permissionResourceTotalRows: 0,
    permissionResources: [],
    permissionOptions: [],
    resourcesOptions: [],
  },
  getters: {
    roles: state => state.roles,
    totalRows: state => state.totalRows,
    permissionResourceTotalRows: state => state.permissionResourceTotalRows,
    permissionResources: state => state.permissionResources,
    permissionOptions: state => state.permissionOptions,
    resourcesOptions: state => state.resourcesOptions,
  },
  mutations: {
    SET_ROLES: (state, { total, records }) => {
      state.totalRows = total
      state.roles = records
    },
    SET_PERMISSION_RESOURCES: (state, { total, records }) => {
      state.permissionResourceTotalRows = total
      state.permissionResources = records
    },
    SET_BEGIN: (state, { permissions, resources }) => {
      state.permissionOptions = permissions
      state.resourcesOptions = resources
    },
  },
  actions: {
    async getRoles({ commit }, params) {
      try {
        const response = await axios.get('auth/roles', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_ROLES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getAllRoles({ commit }) {
      try {
        const response = await axios.get('uni/roles/getAll')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const total = data.data.length
          const records = data.data
          commit('SET_ROLES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async createRole({ commit }, params) {
      try {
        const response = await axios.post('auth/roles', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateRole({ commit }, params) {
      try {
        const response = await axios.put(`auth/roles/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteRole({ commit }, id) {
      try {
        const response = await axios.delete(`auth/roles/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getPermissionResources({ commit }, { id, params }) {
      try {
        const response = await axios.get(`auth/roles/${id}/rolePermissionResource`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_PERMISSION_RESOURCES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async permissionResourcesBegin({ commit }) {
      try {
        const response = await axios.get('auth/roles/rolePermissionResource/begin')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { permissions, resources } = data.data
          commit('SET_BEGIN', { permissions, resources })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createRolePermissionResource({ commit }, { id, rolePermissionResources }) {
      try {
        const response = await axios.post(`auth/roles/${id}/rolePermissionResource`, { rolePermissionResources })
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteRolePermissionResource({ commit }, { id, param }) {
      try {
        const response = await axios.delete(`auth/roles/${id}/rolePermissionResource`, { data: param })
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
