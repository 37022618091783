import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    trainingSystems: [],
    courses: [],
    semesters: [],
  },
  getters: {
    trainingSystems: state => state.trainingSystems,
    courses: state => state.courses,
    semesters: state => state.semesters,
  },
  mutations: {
    SET_TRAINING_SYSTEMS: (state, data) => { state.trainingSystems = data },
    SET_COURSES: (state, data) => { state.courses = data },
    SET_SEMESTERS: (state, data) => { state.semesters = data },
  },
  actions: {
    async getTrainingSystemsByOrganizationId({ commit }, params) {
      try {
        const response = await axios.get('uni/trainingSystems/getByOrganizationId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TRAINING_SYSTEMS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCoursesByTrainingSystemId({ commit }, params) {
      try {
        const response = await axios.get('uni/courses/getByTrainingSystemId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_COURSES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getSemestersByCourseId({ commit }, params) {
      try {
        const response = await axios.get('uni/semesters/getByCourseId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_SEMESTERS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
