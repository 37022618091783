import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    listStudentInfo: [],
    listRevenues: [],
    listExemptions: [],
    listHistoryStudentInfo: [],
    listHistoryRevenuesCN: [],
    listHistoryRevenuesDN: [],
    listHistoryExemptions: [],
    listRevenuesKTK: [],
    listRevenuesKTKTotal: [],
  },
  getters: {
    listStudentInfo: state => state.listStudentInfo,
    listRevenues: state => state.listRevenues,
    listRevenuesKTK: state => state.listRevenuesKTK,
    listRevenuesKTKTotal: state => state.listRevenuesKTKTotal,
    listExemptions: state => state.listExemptions,
    listHistoryStudentInfo: state => state.listHistoryStudentInfo,
    listHistoryRevenuesCN: state => state.listHistoryRevenuesCN,
    listHistoryRevenuesDN: state => state.listHistoryRevenuesDN,
    listHistoryExemptions: state => state.listHistoryExemptions,
  },
  mutations: {
    SET_ALL: (state, { listStudentInfo, listRevenues, listExemptions }) => {
      state.listStudentInfo = listStudentInfo
      state.listRevenues = listRevenues
      state.listExemptions = listExemptions
    },
    SELL_HISTORY: (state, { listHistoryStudentInfo, listHistoryRevenuesCN, listHistoryRevenuesDN, listHistoryExemptions }) => {
      state.listHistoryStudentInfo = listHistoryStudentInfo
      state.listHistoryRevenuesCN = listHistoryRevenuesCN
      state.listHistoryRevenuesDN = listHistoryRevenuesDN
      state.listHistoryExemptions = listHistoryExemptions
    },
    SET_ALL_KTK: (state, { listRevenuesKTKTotal, listRevenuesKTK }) => {
      state.listRevenuesKTKTotal = listRevenuesKTKTotal
      state.listRevenuesKTK = listRevenuesKTK
    },
  },
  actions: {
    async getReceipt({ commit }, params) {
      try {
        const responseStudentInfo = await axios.get('uni/receipt/student-info', { params })
        const responseRevenues = await axios.get('uni/receipt/revenues', { params })
        const responseExemptions = await axios.get('uni/receipt/exemptions', { params })
        if (responseStudentInfo.data.code === ApiCode.SUCCESS && responseRevenues.data.code === ApiCode.SUCCESS && responseExemptions.data.code === ApiCode.SUCCESS) {
          commit('SET_ALL', { listStudentInfo: responseStudentInfo.data.data, listRevenues: responseRevenues.data.data, listExemptions: responseExemptions.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getReceiptKTK({ commit }, params) {
      try {
        const responseRevenuesKTKTotal = await axios.get('uni/receipt/revenuesktk-total', { params })
        const responseRevenuesKTK = await axios.get('uni/receipt/revenuesktk', { params })
        if (responseRevenuesKTKTotal.data.code === ApiCode.SUCCESS && responseRevenuesKTK.data.code === ApiCode.SUCCESS) {
          commit('SET_ALL_KTK', { listRevenuesKTKTotal: responseRevenuesKTKTotal.data.data, listRevenuesKTK: responseRevenuesKTK.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createReceipt({ commit }, params) {
      try {
        const response = await axios.post('uni/receipt', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return {
            isSuccessful,
            message: data.message,
          }
        }
        return {
          isSuccessful,
          message: data.debugMessage,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createReceiptKTK({ commit }, params) {
      try {
        const response = await axios.post('uni/receipt/create-receipt-ktk', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return {
            isSuccessful,
            message: data.message,
          }
        }
        return {
          isSuccessful,
          message: data.debugMessage,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getHistoryReceipt({ commit }, params) {
      try {
        const responseHistoryStudentInfo = await axios.get('uni/receipt/history-student-info', { params })
        const responseHistoryRevenuesCN = await axios.get('uni/receipt/history-revenues-cn', { params })
        const responseHistoryRevenuesDN = await axios.get('uni/receipt/history-revenues-dn', { params })
        const responseHistoryExemptions = await axios.get('uni/receipt/history-exemptions', { params })
        if (responseHistoryStudentInfo.data.code === ApiCode.SUCCESS && responseHistoryRevenuesCN.data.code === ApiCode.SUCCESS && responseHistoryRevenuesDN.data.code === ApiCode.SUCCESS && responseHistoryExemptions.data.code === ApiCode.SUCCESS) {
          commit('SELL_HISTORY', { listHistoryStudentInfo: responseHistoryStudentInfo.data.data, listHistoryRevenuesCN: responseHistoryRevenuesCN.data.data, listHistoryRevenuesDN: responseHistoryRevenuesDN.data.data, listHistoryExemptions: responseHistoryExemptions.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
