import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    roles: [],
  },
  getters: {
    roles: state => state.roles,
  },
  mutations: {
    SET_RESEARCH_ARTICLE_ROLE: (state, data) => { state.roles = data },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/research_article_role', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_RESEARCH_ARTICLE_ROLE', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
