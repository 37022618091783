import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.chart,
})
export default {
  namespaced: true,
  state: {
    countStudent: [],
    countEmployees: [],
    employeesByDepartment: [],
    countStudentByMajorAndCourses1: [],
    countStudentByMajorAndCourses2: [],
    countStudentByMajorAndCourses3: [],
    countStudentByMajorAndCourses4: [],
    coursesName: [],
    countTeachersAndCreditClassByDepartment: [],
    subjectsByYearAndSemester: [],
    countPointBySubject: [],
    countStudentBySubjectType: [],
    countSuspendStudent: [],
    countCreditClassBySubjectType: [],
    countScoreByCreditClass: [],
    countClassByCoursesAndDepartment: [],
    countStudentByItem: [],
    // countStudentAllowanceAndPolicy: [],
    countStudentScholarshipByDepartment: [],
    coursesByTrainingSystemAndYear: [],
  },
  getters: {
    countStudent: state => state.countStudent,
    countEmployees: state => state.countEmployees,
    employeesByDepartment: state => state.employeesByDepartment,
    countStudentByMajorAndCourses1: state => state.countStudentByMajorAndCourses1,
    countStudentByMajorAndCourses2: state => state.countStudentByMajorAndCourses2,
    countStudentByMajorAndCourses3: state => state.countStudentByMajorAndCourses3,
    countStudentByMajorAndCourses4: state => state.countStudentByMajorAndCourses4,
    coursesName: state => state.coursesName,
    countTeachersAndCreditClassByDepartment: state => state.countTeachersAndCreditClassByDepartment,
    subjectsByYearAndSemester: state => state.subjectsByYearAndSemester,
    countPointBySubject: state => state.countPointBySubject,
    countStudentBySubjectType: state => state.countStudentBySubjectType,
    countSuspendStudent: state => state.countSuspendStudent,
    countCreditClassBySubjectType: state => state.countCreditClassBySubjectType,
    countScoreByCreditClass: state => state.countScoreByCreditClass,
    countClassByCoursesAndDepartment: state => state.countClassByCoursesAndDepartment,
    countStudentByItem: state => state.countStudentByItem,
    // countStudentAllowanceAndPolicy: state => state.countStudentAllowanceAndPolicy,
    countStudentScholarshipByDepartment: state => state.countStudentScholarshipByDepartment,
    coursesByTrainingSystemAndYear: state => state.coursesByTrainingSystemAndYear,
  },
  mutations: {
    SET_STUDENT: (state, data) => { state.countStudent = data },
    SET_EMPLOYEES: (state, data) => { state.countEmployees = data },
    SET_EMPLOYEES_BY_DEPARTMENT: (state, data) => { state.employeesByDepartment = data },
    SET_STUDENT_BY_MAJOR_AND_COURSES_1: (state, data) => { state.countStudentByMajorAndCourses1 = data },
    SET_STUDENT_BY_MAJOR_AND_COURSES_2: (state, data) => { state.countStudentByMajorAndCourses2 = data },
    SET_STUDENT_BY_MAJOR_AND_COURSES_3: (state, data) => { state.countStudentByMajorAndCourses3 = data },
    SET_STUDENT_BY_MAJOR_AND_COURSES_4: (state, data) => { state.countStudentByMajorAndCourses4 = data },
    SET_COURSES_NAME: (state, data) => { state.coursesName = data },
    SET_TEACHERS_AND_CREDIT_CLASS_BY_DEPARTMENT: (state, data) => { state.countTeachersAndCreditClassByDepartment = data },
    SET_SUBJECT_BY_YEAR_AND_SEMESTER: (state, data) => { state.subjectsByYearAndSemester = data },
    SET_COUNT_POINT_BY_SUBJECT: (state, data) => { state.countPointBySubject = data },
    SET_STUDENT_BY_SUBJECT_TYPE: (state, data) => { state.countStudentBySubjectType = data },
    SET_COUNT_SUSPEND_STUDENT: (state, data) => { state.countSuspendStudent = data },
    SET_COUNT_CREDIT_CLASS_BY_SUBJECT_TYPE: (state, data) => { state.countCreditClassBySubjectType = data },
    SET_COUNT_SCORE_BY_CREDIT_CLASS: (state, data) => { state.countScoreByCreditClass = data },
    SET_COUNT_CLASS_BY_COURSES_AND_DEPARTMENT: (state, data) => { state.countClassByCoursesAndDepartment = data },
    SET_COUNT_STUDENT_BY_ITEM: (state, data) => { state.countStudentByItem = data },
    // SET_COUNT_STUDENT_ALLOWANCE_AND_POLICY: (state, data) => {state.countStudentAllowanceAndPolicy = data},
    SET_COUNT_STUDENT_SCHOLARSHIP_BY_DEPARTMENT: (state, data) => { state.countStudentScholarshipByDepartment = data },
    SET_COURSES_BY_TRAINING_SYSTEM_AND_YEAR: (state, data) => { state.coursesByTrainingSystemAndYear = data },
  },
  actions: {
    async getStudentOverview({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/countStudentOverview`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { countStudent } = data.data
          commit('SET_STUDENT', countStudent)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getEmployeesOverview({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/countEmployeesOverview`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { countEmployees } = data.data
          commit('SET_EMPLOYEES', countEmployees)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getEmployeesByDepartment({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/employeesByDepartment`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { countEmployeesByDepartment } = data.data
          commit('SET_EMPLOYEES_BY_DEPARTMENT', countEmployeesByDepartment)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudentByMajorAndCourses({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/countStudentByMajorAndCourses`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            countStudentByMajorAndCourses1, countStudentByMajorAndCourses2, countStudentByMajorAndCourses3, countStudentByMajorAndCourses4, coursesName,
          } = data.data
          commit('SET_STUDENT_BY_MAJOR_AND_COURSES_1', countStudentByMajorAndCourses1)
          commit('SET_STUDENT_BY_MAJOR_AND_COURSES_2', countStudentByMajorAndCourses2)
          commit('SET_STUDENT_BY_MAJOR_AND_COURSES_3', countStudentByMajorAndCourses3)
          commit('SET_STUDENT_BY_MAJOR_AND_COURSES_4', countStudentByMajorAndCourses4)
          commit('SET_COURSES_NAME', coursesName)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTeachersAndCreditClassByDepartment({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/countTeachersAndCreditClassByDepartment`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { countTeachersAndCreditClassByDepartment } = data.data
          commit('SET_TEACHERS_AND_CREDIT_CLASS_BY_DEPARTMENT', countTeachersAndCreditClassByDepartment)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getSubjectsByYearAndSemester({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/selectSubjectsByYearAndSemester`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { subjectsByYearAndSemester } = data.data
          commit('SET_SUBJECT_BY_YEAR_AND_SEMESTER', subjectsByYearAndSemester)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCoursesByTrainingSystemAndYear({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/selectCoursesByTrainingSystemAndYear`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { coursesByTrainingSystemAndYear } = data.data
          commit('SET_COURSES_BY_TRAINING_SYSTEM_AND_YEAR', coursesByTrainingSystemAndYear)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCountPointBySubject({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/countPointBySubject`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { countPointBySubject } = data.data
          commit('SET_COUNT_POINT_BY_SUBJECT', countPointBySubject)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCountStudentBySubjectType({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/countStudentBySubjectType`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { countStudentBySubjectType } = data.data
          commit('SET_STUDENT_BY_SUBJECT_TYPE', countStudentBySubjectType)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCountSuspendStudent({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/countSuspendStudent`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { countSuspendStudent } = data.data
          commit('SET_COUNT_SUSPEND_STUDENT', countSuspendStudent)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCountScoreByCreditClass({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/countScoreByCreditClass`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { countScoreByCreditClass } = data.data
          commit('SET_COUNT_SCORE_BY_CREDIT_CLASS', countScoreByCreditClass)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCountClassByCoursesAndDepartment({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/countClassByCoursesAndDepartment`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { countClassByCoursesAndDepartment } = data.data
          commit('SET_COUNT_CLASS_BY_COURSES_AND_DEPARTMENT', countClassByCoursesAndDepartment)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // async getCountStudentDisciplineAndAward ({ commit }, params) {
    //   try {
    //     const response = await axios.get(`${baseAttr.basePath}/countStudentDisciplineAndAward`, { params })
    //     const { data } = response
    //     if (data.code === ApiCode.SUCCESS) {
    //       const { countStudentDisciplineAndAward } = data.data
    //       commit('SET_COUNT_STUDENT_DISCIPLINE_AND_AWARD', countStudentDisciplineAndAward)
    //     }
    //   } catch (e) {
    //     handleCatch(e)
    //   }
    // },
    async getCountStudentByItem({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/countStudentByItem`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { countStudentByItem } = data.data
          commit('SET_COUNT_STUDENT_BY_ITEM', countStudentByItem)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCountCreditClassBySubjectType({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/countCreditClassBySubjectType`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { countCreditClassBySubjectType } = data.data
          commit('SET_COUNT_CREDIT_CLASS_BY_SUBJECT_TYPE', countCreditClassBySubjectType)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCountStudentScholarshipByDepartment({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/countStudentScholarshipByDepartment`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { countStudentScholarshipByDepartment } = data.data
          commit('SET_COUNT_STUDENT_SCHOLARSHIP_BY_DEPARTMENT', countStudentScholarshipByDepartment)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
