import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    navMenuItems: [],
    menus: [],
    totalRows: 0,
  },
  getters: {
    navMenuItems: state => state.navMenuItems,
    menus: state => state.menus,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_NAV_MENU_ITEMS: (state, menus) => { state.navMenuItems = menus },
    SET_MENUS: (state, { total, records }) => {
      state.totalRows = total
      state.menus = records
    },
  },
  actions: {
    async getNavMenuItems({ commit }) {
      try {
        const response = await axios.get('auth/menus/all')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { menus } = data.data
          commit('SET_NAV_MENU_ITEMS', menus)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getMenus({ commit }, params) {
      try {
        const response = await axios.get('auth/menus', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_MENUS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createMenu({ commit }, params) {
      try {
        const response = await axios.post('auth/menus', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateMenu({ commit }, params) {
      try {
        const response = await axios.put(`auth/menus/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteMenu({ commit }, id) {
      try {
        const response = await axios.delete(`auth/menus/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
