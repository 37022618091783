import { CREDENTIAL_TYPE, STATUSES } from '@/const/status'
import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    statuses: STATUSES,
    types: CREDENTIAL_TYPE,
    certificateBlanks: [],
    totalRows: 0,
    certificateBlankFiles: [],
    url: '',
  },
  getters: {
    statuses: state => state.statuses,
    types: state => state.types,
    certificateBlanks: state => state.certificateBlanks,
    totalRows: state => state.totalRows,
    certificateBlankFiles: state => state.certificateBlankFiles,
    url: state => state.url,
  },
  mutations: {
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
    SET_CERTIFICATE_BLANKS: (state, certificateBlanks) => {
      state.certificateBlanks = certificateBlanks
    },
    SET_TOTAL_ROWS: (state, data) => {
      state.totalRows = data
    },
    SET_CERTIFICATE_BLANK_FILES: (state, certificateBlankFiles) => {
      state.certificateBlankFiles = certificateBlankFiles
    },
    SET_URL: (state, url) => {
      state.url = url
    },
  },
  actions: {
    async readCertificateBlanks({ commit }, params) {
      try {
        const response = await axios.get('certificates/certificate-blanks', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_CERTIFICATE_BLANKS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCertificateBlankById({ commit }, id) {
      try {
        const response = await axios.get(`certificates/certificate-blanks/getById?id=${id}`)
        const { data } = response
        return { data }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async readCertificateBlankFiles({ commit }, id) {
      try {
        const response = await axios.get(`certificates/certificate-blanks/getFiles/${id}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_CERTIFICATE_BLANK_FILES', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createCertificateBlank({ commit }, params) {
      try {
        const response = await axios.post('certificates/certificate-blanks', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateCertificateBlank({ commit }, params) {
      try {
        const response = await axios.put(`certificates/certificate-blanks/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteCertificateBlank({ commit }, id) {
      try {
        const response = await axios.delete(`certificates/certificate-blanks/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async uploadSample({ commit }, params) {
      try {
        const response = await axios.post('certificates/certificate-blanks/uploadSample', params)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
        return []
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getPathFileSample({ commit }, params) {
      try {
        const response = await axios.get('certificates/certificate-blanks/getFileMinioByPathFile', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
        return []
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
