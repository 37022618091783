import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/admin/config_register_subject',
    name: 'registerSubject',
    component: () => import('@/views/config_register_subject/Index.vue'),
    meta: {
      pageTitle: 'Cấu hình thời gian đăng ký học phần',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: true,
        },
        {
          text: 'Cấu hình thời gian đăng ký học phần',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.REGISTER_SUBJECT,
    },
  },
  {
    path: '/admin/scoring-formula',
    name: 'scoringFormula',
    component: () => import('@/views/admin/scoring-formula/ScoringFormula.vue'),
    meta: {
      pageTitle: 'Cấu hình Công thức tính điểm',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: true,
        },
        {
          text: 'Cấu hình công thức tính điểm',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SCORING_FORMULA,
    },
  },
]
