import { STATUSES } from '@/const/status'
import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    statuses: STATUSES,
    credentialImplements: [],
    totalRows: 0,
  },
  getters: {
    statuses: state => state.statuses,
    credentialImplements: state => state.credentialImplements,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
    SET_CREDENTIAL_IMPLEMENT: (state, credentialImplements) => {
      state.credentialImplements = credentialImplements
    },
    SET_TOTAL_ROWS: (state, data) => {
      state.totalRows = data
    },
  },
  actions: {
    async readCredentialImplements({ commit }, params) {
      try {
        const response = await axios.get('certificates/credential_implements', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_CREDENTIAL_IMPLEMENT', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async createCredentialImplement({ commit }, params) {
      try {
        const response = await axios.post('certificates/credential_implements', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateCredentialImplement({ commit }, params) {
      try {
        const response = await axios.put(`certificates/credential_implements/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteCredentialImplement({ commit }, id) {
      try {
        const response = await axios.delete(`certificates/credential_implements/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    }
  },
}
