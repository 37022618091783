import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    reports: [],
    totalRows: 0,
    reportConfigurations: [],
  },
  getters: {
    reports: state => state.reports,
    totalRows: state => state.totalRows,
    reportConfigurations: state => state.reportConfigurations,
  },
  mutations: {
    SET_REPORTS: (state, data) => { state.reports = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
    SET_REPORT_CONFIGURATIONS: (state, data) => { state.reportConfigurations = data },
  },
  actions: {
    async getReportsByOrganizationId({ commit }, params) {
      try {
        const response = await axios.get('uni/reports', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_REPORTS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getReportConfigurationsByReportId({ commit }, id) {
      try {
        const response = await axios.get(`uni/reports/${id}/configurations`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_REPORT_CONFIGURATIONS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateConfiguration({ commit }, params) {
      try {
        const response = await axios.put(`uni/reports/configurations/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteConfiguration({ commit }, id) {
      try {
        const response = await axios.delete(`uni/reports/configurations/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
