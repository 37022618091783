import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/major-approval',
    name: 'majorApproval',
    component: () => import('@/views/major-approval/Index.vue'),
    meta: {
      pageTitle: 'Quản lý kế hoạch xét chuyên ngành',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: false,
        },
        {
          text: 'Xét chuyên ngành',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.MAJOR_APPROVAL,
    },
  },
]
