import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch, mapDropdown } from '@/utils'
import { STATUSES } from '@/const/status'
import { defaultParams } from '@/store/common/dropdown'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    avgCreditNum: '',
    statuses: STATUSES,
    resourceName: {
      fullName: 'Dự kiến lịch thi môn học',
      shortName: 'Lịch thi MH',
    },
    maxOrderNo: 0,
    admissionExaminationSchedulerSubjects: [],
    admissionExaminationSchedulerSubjectDates: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    resourceName: state => state.resourceName,
    admissionExaminationSchedulerSubjects: state => state.admissionExaminationSchedulerSubjects,
    admissionExaminationSchedulerSubjectDates: state => state.admissionExaminationSchedulerSubjectDates,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
    SET_ADMISSION_EXAMINATION_SCHEDULER_SUBJECTS: (state, records) => {
      state.admissionExaminationSchedulerSubjects = records
    },
    SET_ADMISSION_EXAMINATION_SCHEDULER_SUBJECT_DATES: (state, records) => {
      state.admissionExaminationSchedulerSubjectDates = records
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_examination_scheduler_subjects', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_LISTS', {
            total,
            records,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createData(commit, params) {
      try {
        const response = await axios.post('/admissions/admission_examination_scheduler_subjects', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/admission_examination_scheduler_subjects/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/admission_examination_scheduler_subjects/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createPlanMainData({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_examination_scheduler_subjects/plan', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getAdmissionExaminationSchedulerSubjects({ commit }, parameters) {
      try {
        const params = {
          ...defaultParams,
          ...parameters,
        }
        const response = await axios.get('/admissions/admission_examination_scheduler_subjects', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapOptionalData = records.map(item => ({
            value: item.id,
            label: item.startDate,
          }))
          const mapData = mapDropdown(records)
          commit('SET_ADMISSION_EXAMINATION_SCHEDULER_SUBJECT_DATES', mapOptionalData)
          commit('SET_ADMISSION_EXAMINATION_SCHEDULER_SUBJECTS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
