import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/admin/schedule',
    name: 'schedule',
    component: () => import('@/views/schedule/Schedule.vue'),
    meta: {
      title: 'Quản lý thời khóa biểu',
    },
  },
  {
    path: '/admin/scheduler',
    name: 'scheduler',
    component: () => import('@/views/scheduler/Scheduler.vue'),
    meta: {
      pageTitle: 'Thời khóa biểu',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: false,
        },
        {
          text: 'Thời khóa biểu',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.SCHEDULE,
    },
  },
]
