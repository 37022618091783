import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export const baseAttr = Object.freeze({
    basePath: ApiExtRoutes.evaluateManagement,
})

export default {
    namespaced: true,
    state: {
        dataSource: [],
        totalRows: 0,
        totalStudentRows: 0,
        allSetOfCriterias: [],
        allEvaluateObjectGroups: [],
        allEvaluatedObjectGroups: [],
        setOfCriteriaObjects: [],
        yearsSetOfCriteriaObjects: [],
        subjectsSetOfCriteriaObject: [],
        creditClassesSetOfCriteriaObject: [],
        resultEvaluateCreditClass: {},
        studentsEvaluatedCreditClass: [],
        studentsEvaluatedAccordingToSetOfCriteriaObject: [],
        totalStudentsEvaluatedAccordingToSetOfCriteriaObjectRows: [],
        studentsNotEvaluatedAccordingToSetOfCriteriaObject: [],
        totalStudentsNotEvaluatedAccordingToSetOfCriteriaObjectRows: [],
        statuses: STATUSES,
        statisticForSetOfCriteriaObjectRefresh: [],
        statisticForSetOfCriteriaObjectLastTime: [],
        statisticForSetOfCriteriaObjectAdvance: [],
        totalUsersEvaluated: 0,
        usersEvaluatedRows: [],
    },
    getters: {
        dataSource: state => state.dataSource,
        totalRows: state => state.totalRows,
        totalStudentRows: state => state.totalStudentRows,
        allSetOfCriterias: state => state.allSetOfCriterias,
        allEvaluateObjectGroups: state => state.allEvaluateObjectGroups,
        allEvaluatedObjectGroups: state => state.allEvaluatedObjectGroups,
        setOfCriteriaObjects: state => state.setOfCriteriaObjects,
        yearsSetOfCriteriaObjects: state => state.yearsSetOfCriteriaObjects,
        subjectsSetOfCriteriaObject: state => state.subjectsSetOfCriteriaObject,
        creditClassesSetOfCriteriaObject: state => state.creditClassesSetOfCriteriaObject,
        resultEvaluateCreditClass: state => state.resultEvaluateCreditClass,
        studentsEvaluatedCreditClass: state => state.studentsEvaluatedCreditClass,
        studentsEvaluatedAccordingToSetOfCriteriaObject: state => state.studentsEvaluatedAccordingToSetOfCriteriaObject,
        totalStudentsEvaluatedAccordingToSetOfCriteriaObjectRows: state => state.totalStudentsEvaluatedAccordingToSetOfCriteriaObjectRows,
        studentsNotEvaluatedAccordingToSetOfCriteriaObject: state => state.studentsNotEvaluatedAccordingToSetOfCriteriaObject,
        totalStudentsNotEvaluatedAccordingToSetOfCriteriaObjectRows: state => state.totalStudentsNotEvaluatedAccordingToSetOfCriteriaObjectRows,
        statuses: state => state.statuses,
        statisticForSetOfCriteriaObjectRefresh: state => state.statisticForSetOfCriteriaObjectRefresh,
        statisticForSetOfCriteriaObjectLastTime: state => state.statisticForSetOfCriteriaObjectLastTime,
        statisticForSetOfCriteriaObjectAdvance: state => state.statisticForSetOfCriteriaObjectAdvance,
        totalUsersEvaluated: state => state.totalUsersEvaluated,
        usersEvaluatedRows: state => state.usersEvaluatedRows,
    },
    mutations: {
        SET_DATA_SOURCE: (state, records) => {
            state.dataSource = records
        },
        SET_TOTAL_ROWS: (state, total) => {
            state.totalRows = total
        },
        ALL_SET_OF_CRITERIAS: (state, allSetOfCriterias) => {
            state.allSetOfCriterias = allSetOfCriterias
        },
        ALL_SET_EVALUATE_OBJECT_GROUPS: (state, allEvaluateObjectGroups) => {
            state.allEvaluateObjectGroups = allEvaluateObjectGroups
        },
        ALL_SET_EVALUATED_OBJECT_GROUPS: (state, allEvaluatedObjectGroups) => {
            state.allEvaluatedObjectGroups = allEvaluatedObjectGroups
        },
        SET_OF_CRITERIA_OBJECTS: (state, setOfCriteriaObjects) => {
            state.setOfCriteriaObjects = setOfCriteriaObjects
        },
        SET_YEARS_SET_OF_CRITERIA_OBJECTS: (state, yearsSetOfCriteriaObjects) => {
            state.yearsSetOfCriteriaObjects = yearsSetOfCriteriaObjects
        },
        SET_SUBJECTS_SET_OF_CRITERIA_OBJECT: (state, subjectsSetOfCriteriaObject) => {
            state.subjectsSetOfCriteriaObject = subjectsSetOfCriteriaObject
        },
        SET_CREDIT_CLASSES_SET_OF_CRITERIA_OBJECT: (state, creditClassesSetOfCriteriaObject) => {
            state.creditClassesSetOfCriteriaObject = creditClassesSetOfCriteriaObject
        },
        SET_RESULT_EVALUATE_CREDIT_CLASS: (state, data) => {
            state.resultEvaluateCreditClass = data
        },
        SET_STUDENTS_EVALUATED_CREDIT_CLASS: (state, data) => {
            state.studentsEvaluatedCreditClass = data
        },
        SET_TOTAL_STUDENT_ROWS: (state, total) => {
            state.totalStudentRows = total
        },
        SET_STUDENTS_EVALUATED_ACCORDING_TO_SET_OF_CRITERIA_OBJECT: (state, data) => {
            state.studentsEvaluatedAccordingToSetOfCriteriaObject = data
        },
        SET_TOTAL_STUDENTS_EVALUATED_ACCORDING_TO_SET_OF_CRITERIA_OBJECT_ROWS: (state, total) => {
            state.totalStudentsEvaluatedAccordingToSetOfCriteriaObjectRows = total
        },
        SET_STUDENTS_NOT_EVALUATED_ACCORDING_TO_SET_OF_CRITERIA_OBJECT: (state, data) => {
            state.studentsNotEvaluatedAccordingToSetOfCriteriaObject = data
        },
        SET_TOTAL_STUDENTS_NOT_EVALUATED_ACCORDING_TO_SET_OF_CRITERIA_OBJECT_ROWS: (state, total) => {
            state.totalStudentsNotEvaluatedAccordingToSetOfCriteriaObjectRows = total
        },
        SET_STATISTIC_FOR_SET_OF_CRITERIA_OBJECT_REFRESH: (state, data) => {
            state.statisticForSetOfCriteriaObjectRefresh = data
        },
        SET_STATISTIC_FOR_SET_OF_CRITERIA_OBJECT_LAST_TIME: (state, data) => {
            state.statisticForSetOfCriteriaObjectLastTime = data
        },
        SET_STATISTIC_FOR_SET_OF_CRITERIA_OBJECT_ADVANCE: (state, data) => {
            state.statisticForSetOfCriteriaObjectAdvance = data
        },
        SET_USERS_EVALUATED: (state, data) => {
            state.totalUsersEvaluated = data
        },
        SET_TOTAL_USERS_EVALUATED_ROWS: (state, data) => {
            state.usersEvaluatedRows = data
        },
    },
    actions: {
        async begin({ commit }, params) {
            try {
                const response = await axios.get(`${baseAttr.basePath}/begin`, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { allSetOfCriterias, allEvaluateObjectGroups, allEvaluatedObjectGroups, yearsSetOfCriteriaObjects } = data.data
                    commit('ALL_SET_OF_CRITERIAS', allSetOfCriterias)
                    commit('ALL_SET_EVALUATE_OBJECT_GROUPS', allEvaluateObjectGroups)
                    commit('ALL_SET_EVALUATED_OBJECT_GROUPS', allEvaluatedObjectGroups)
                    commit('SET_YEARS_SET_OF_CRITERIA_OBJECTS', yearsSetOfCriteriaObjects)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getSetOfCriteriaObjectsByYear({ commit }, params) {
            try {
                const response = await axios.get(`${baseAttr.basePath}/getSetOfCriteriaObjectsByYear`, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_OF_CRITERIA_OBJECTS', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getSubjectsBySetOfCriteriaObject({ commit }, params) {
            try {
                const response = await axios.get(`${baseAttr.basePath}/getSubjectsBySetOfCriteriaObject`, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_SUBJECTS_SET_OF_CRITERIA_OBJECT', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getCreditClassesBySetOfCriteriaObject({ commit }, params) {
            try {
                const response = await axios.get(`${baseAttr.basePath}/getCreditClassesBySetOfCriteriaObject`, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_CREDIT_CLASSES_SET_OF_CRITERIA_OBJECT', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getCreditClasses({ commit }, params) {
            try {
                const response = await axios.get(baseAttr.basePath, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const {total, records} = data.data
                    commit('SET_DATA_SOURCE', records)
                    commit('SET_TOTAL_ROWS', total)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getResultEvaluateCreditClass({ commit }, params) {
            try {
                const response = await axios.get(`${baseAttr.basePath}/getResultEvaluateCreditClass`, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_RESULT_EVALUATE_CREDIT_CLASS', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getStudentsEvaluatedCreditClass({ commit }, params) {
            try {
                const response = await axios.get(`${baseAttr.basePath}/getStudentsEvaluatedCreditClass`, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const {total, records} = data.data
                    commit('SET_STUDENTS_EVALUATED_CREDIT_CLASS', records)
                    commit('SET_TOTAL_STUDENT_ROWS', total)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getStudentsEvaluatedAccordingToSetOfCriteriaObject({ commit }, params) {
            try {
                const response = await axios.get(`${baseAttr.basePath}/getStudentsEvaluatedAccordingToSetOfCriteriaObject`, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const {total, records} = data.data
                    commit('SET_STUDENTS_EVALUATED_ACCORDING_TO_SET_OF_CRITERIA_OBJECT', records)
                    commit('SET_TOTAL_STUDENTS_EVALUATED_ACCORDING_TO_SET_OF_CRITERIA_OBJECT_ROWS', total)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getStudentsNotEvaluatedAccordingToSetOfCriteriaObject({ commit }, params) {
            try {
                const response = await axios.get(`${baseAttr.basePath}/getStudentsNotEvaluatedAccordingToSetOfCriteriaObject`, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const {total, records} = data.data
                    commit('SET_STUDENTS_NOT_EVALUATED_ACCORDING_TO_SET_OF_CRITERIA_OBJECT', records)
                    commit('SET_TOTAL_STUDENTS_NOT_EVALUATED_ACCORDING_TO_SET_OF_CRITERIA_OBJECT_ROWS', total)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getStatisticForSetOfCriteriaObjectRefresh({ commit }, params) {
            try {
                const response = await axios.get(`${baseAttr.basePath}/getStatisticForSetOfCriteriaObjectRefresh`, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_STATISTIC_FOR_SET_OF_CRITERIA_OBJECT_REFRESH', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getStatisticForSetOfCriteriaObjectLastTime({ commit }, params) {
            try {
                const response = await axios.get(`${baseAttr.basePath}/getStatisticForSetOfCriteriaObjectLastTime`, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_STATISTIC_FOR_SET_OF_CRITERIA_OBJECT_LAST_TIME', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getUsersEvaluated({ commit }, params) {
            try {
                const response = await axios.get(`${baseAttr.basePath}/getUsersEvaluated`, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const {total, records} = data.data
                    commit('SET_USERS_EVALUATED', records)
                    commit('SET_TOTAL_USERS_EVALUATED_ROWS', total)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        // async getSubjectsBySetOfCriteriaObject({ commit }, params) {
        //     try {
        //         const response = await axios.get(`${baseAttr.basePath}/getSubjectsBySetOfCriteriaObject`, { params })
        //         const { data } = response
        //         if (data.code === ApiCode.SUCCESS) {
        //             commit('SET_SUBJECTS_SET_OF_CRITERIA_OBJECT', data.data)
        //         }
        //     } catch (e) {
        //         handleCatch(e)
        //     }
        // },
        async getCreditClassesSubjectBySetOfCriteriaObject({ commit }, params) {
            try {
                const response = await axios.get(`${baseAttr.basePath}/getCreditClassesSubjectBySetOfCriteriaObject`, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_CREDIT_CLASSES_SET_OF_CRITERIA_OBJECT', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getStatisticForSetOfCriteriaObjectAdvance({ commit }, params) {
            try {
                const response = await axios.get(`${baseAttr.basePath}/getStatisticForSetOfCriteriaObjectAdvance`, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_STATISTIC_FOR_SET_OF_CRITERIA_OBJECT_ADVANCE', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
    },
}
