import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    admissionMajors: [],
    totalRows: 0,
    statuses: STATUSES,
    dataListMajorByAdmissionForm: [],
  },
  getters: {
    admissionMajors: state => state.admissionMajors,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    dataListMajorByAdmissionForm: state => state.dataListMajorByAdmissionForm,
  },
  mutations: {
    SET_ADMISSION_MAJORS: (state, { total, records }) => {
      state.totalRows = total
      state.admissionMajors = records
    },
    SET_MAJOR_BY_ADMISSION_FORM: (state, data) => { state.dataListMajorByAdmissionForm = data },
  },
  actions: {
    async readAdmissionMajors({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_major', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_ADMISSION_MAJORS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createAdmissionMajors({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_major', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateAdmissionMajors({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/admission_major/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteAdmissionMajors({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/admission_major/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMajorByAdmissionFormId({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_major_form/getByAdmissionFormId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.majorId,
            label: item.majorName,
          }))
          commit('SET_MAJOR_BY_ADMISSION_FORM', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
