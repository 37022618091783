import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    total: 0,
    dataRows: [],
  },
  getters: {
    total: state => state.total,
    dataRows: state => state.dataRows,
  },
  mutations: {
    SET_TOTAL: (state, data) => { state.total = data },
    SET_DATA_ROWS: (state, data) => { state.dataRows = data },
  },
  actions: {
    async getDataRows({ commit }, params) {
      try {
        const response = await axios.get('uni/subjects/reportStudentsRegisteredBySubject', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_DATA_ROWS', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getDetailDataRows({ commit }, params) {
      try {
        const response = await axios.get('uni/subjects/reportDetailStudentsRegisteredSubject', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_DATA_ROWS', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

  },
}
