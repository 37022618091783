import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    admissionRevenues: [],
    totalRows: 0,
    statuses: STATUSES,
    dataAdmissionRevenues: [],
  },
  getters: {
    admissionRevenues: state => state.admissionRevenues,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    dataAdmissionRevenues: state => state.dataAdmissionRevenues,
  },
  mutations: {
    SET_ADMISSION_REVENUES: (state, { total, records }) => {
      state.totalRows = total
      state.admissionRevenues = records
    },
    SET_DATA_ADMISSION_REVENUES: (state, dataAdmissionRevenues) => { state.dataAdmissionRevenues = dataAdmissionRevenues },
},
  actions: {
    async readAdmissionRevenues({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_revenue', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_ADMISSION_REVENUES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

      async readAdmissionRevenueById({ commit }, params) {
          try {
              const response = await axios.get('/admissions/admission_revenue/get_by_id', { params })
              const { data } = response
              if (data.code === ApiCode.SUCCESS) {
                  return data.data
              }
          } catch (e) {
              handleCatch(e)
          }
      },
    async getDataAdmissionRevenues({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_revenue/allActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_DATA_ADMISSION_REVENUES', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createAdmissionRevenues({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_revenue', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateAdmissionRevenues({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/admission_revenue/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteAdmissionRevenues({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/admission_revenue/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
