import { CREDENTIAL_TYPE, STATUSES } from '@/const/status'
import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    studentsCredentialStorage: [],
    types: CREDENTIAL_TYPE,
    totalRows: 0,
    certificateBlankFile: '',
    url: '',
    statuses: STATUSES,
  },
  getters: {
    statuses: state => state.statuses,
    totalRows: state => state.totalRows,
    types: state => state.types,
    studentsCredentialStorage: state => state.studentsCredentialStorage,
    certificateBlankFile: state => state.certificateBlankFile,
    url: state => state.url,
  },
  mutations: {
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
    SET_TOTAL_ROWS: (state, data) => {
      state.totalRows = data
    },
    SET_CREDENTIAL_STORAGE: (state, studentsCredentialStorage) => {
      state.studentsCredentialStorage = studentsCredentialStorage
    },
    SET_CERTIFICATE_BLANK_FILE: (state, certificateBlankFile) => {
      state.certificateBlankFile = certificateBlankFile
    },
    SET_URL: (state, url) => {
      state.url = url
    },
  },
  actions: {
    async readStudentsCredentialStorage({ commit }, params) {
      try {
        const response = await axios.get('certificates/credentials-storage', {params})
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_CREDENTIAL_STORAGE', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async uploadSample({ commit }, params) {
      try {
        const response = await axios.post('certificates/certificate-blanks/uploadSample', params)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
        return []
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getPathFileSample({ commit }, params) {
      try {
        const response = await axios.get('certificates/certificate-blanks/getFileMinioByPathFile', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
        return []
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateCredentialStorage({ commit}, params){
      try {
        const response = await axios.put(`certificates/credentials-storage/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
