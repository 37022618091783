import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/logs',
    name: 'logs',
    component: () => import('@/views/logs/index.vue'),
    meta: {
      pageTitle: 'Quản lý hoạt động',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Quản lý hoạt động',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.LOG_ACTIVITIES,
    },
  },
]
