import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    semesters: [],
    total: 0,
    statuses: [],
    semesterTypes: [],
  },
  getters: {
    semesters: state => state.semesters,
    total: state => state.total,
    statuses: state => state.statuses,
    semesterTypes: state => state.semesterTypes,
  },
  mutations: {
    SET_SEMESTERS: (state, semesters) => { state.semesters = semesters },
    SET_SEMESTER_TYPES: (state, semesterTypes) => { state.semesterTypes = semesterTypes },
    SET_STATUS: (state, statuses) => { state.statuses = statuses },
    SET_TOTAL: (state, total) => { state.total = total },
  },
  actions: {
    async getInit({ commit }) {
      try {
        const response = await axios.get('uni/semesters/begin')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { semesterTypes } = data.data
          const { statuses } = data.data

          commit('SET_SEMESTER_TYPES', semesterTypes)
          commit('SET_STATUS', statuses)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getSemesters({ commit }, params) {
      try {
        const response = await axios.get('uni/semesters', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_SEMESTERS', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createSemester({ commit }, params) {
      try {
        const response = await axios.post('uni/semesters', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateSemester({ commit }, params) {
      try {
        const response = await axios.put(`uni/semesters/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteSemester({ commit }, id) {
      try {
        const response = await axios.delete(`uni/semesters/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getSemestersByCourseId({ commit }, params) {
      try {
        const response = await axios.get('uni/semesters/getByCourseId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_SEMESTERS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
