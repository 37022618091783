import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { PROGRAMME_STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    programmes: [],
    totalRows: 0,
    statuses: PROGRAMME_STATUSES,
    resourceName: { fullName: 'Chương trình đào tạo', shortName: 'CTĐT' },
    maxOrderNo: 0,
  },
  getters: {
    programmes: state => state.programmes,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    resourceName: state => state.resourceName,
    maxOrderNo: state => state.maxOrderNo,
  },
  mutations: {
    SET_PROGRAMMES: (state, { total, records }) => {
      state.totalRows = total
      state.programmes = records
    },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => {
      state.maxOrderNo = maxOrderNo
    },
  },
  actions: {
    async getProgrammes({ commit }, params) {
      try {
        const response = await axios.get('uni/programmes', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_PROGRAMMES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createProgramme({ commit }, params) {
      try {
        const response = await axios.post('uni/programmes', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async copyProgramme({ commit }, params) {
      try {
        const response = await axios.post(`uni/programmes/copy/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateProgramme({ commit }, params) {
      try {
        const response = await axios.put(`uni/programmes/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async deleteProgramme({ commit }, id) {
      try {
        const response = await axios.delete(`uni/programmes/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMaxOrderNo({ commit }) {
      try {
        const response = await axios.get('uni/programmes/max/order-no')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { maxOrderNo } = data.data
          commit('SET_MAX_ORDER_NO', maxOrderNo)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
