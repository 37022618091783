import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataSources: [],
    totalRows: 0,
  },
  getters: {
    dataSources: state => state.dataSources,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_DATA_SOURCES: (state, { total, records }) => {
      state.totalRows = total
      state.dataSources = records
    },
  },
  actions: {
    async getDataSources({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportCountStudentCancelSubject', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getAllData({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportCountStudentCancelSubject', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data.records
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getDataPrint({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportCountStudentCancelSubject/print', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { teachingExam, studentsIneligible } = data.data
          commit('SET_DATA_PRINT', { teachingExam, studentsIneligible })
        }
        return null
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
