import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataSources: [],
    totalRows: 0,
  },
  getters: {
    dataSources: state => state.dataSources,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_DATA_SOURCES: (state, data) => {
      state.dataSources = data
    },
    SET_TOTAL_ROWS: (state, data) => {
      state.totalRows = data
    },
  },
  actions: {
    async getDataSources({ commit }, params) {
      try {
        const response = await axios.get('uni/scholarshipFundCriterias', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createScholarshipFundCriteria({ commit }, params) {
      try {
        const response = await axios.post('uni/scholarshipFundCriterias', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateScholarshipFundCriteria({ commit }, params) {
      try {
        const response = await axios.put(`uni/scholarshipFundCriterias/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteScholarshipFundCriteria({ commit }, id) {
      try {
        const response = await axios.delete(`uni/scholarshipFundCriterias/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
