import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    studentCgpa: [],
    totalRows: 0,
    allStudentClass: [],
    allCourseSemester: [],
  },
  getters: {
    studentCgpa: state => state.studentCgpa,
    totalRows: state => state.totalRows,
    allStudentClass: state => state.allStudentClass,
    allCourseSemester: state => state.allCourseSemester,
  },
  mutations: {
    SET_STUDENT_CGPA: (state, { total, records }) => {
      state.totalRows = total
      state.studentCgpa = records
    },

    SET_ALL: (state, { allStudentClass, allCourseSemester }) => {
      state.allStudentClass = allStudentClass
      state.allCourseSemester = allCourseSemester
    },
  },
  actions: {
    async begin({ commit }) {
      try {
        const responseStudentClass = await axios.get('uni/studentAwards/students')
        const responseCourseSemester = await axios.get('uni/studentAwards/awards')

        if (responseStudentClass.data.code === ApiCode.SUCCESS && responseCourseSemester.data.code === ApiCode.SUCCESS) {
          commit('SET_ALL', { allStudentClass: responseStudentClass.data.data.studentClass, allCourseSemester: responseCourseSemester.data.data.courseSemester })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudentAwards({ commit }, params) {
      try {
        const response = await axios.get('uni/studentAwards', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_STUDENTAWARDS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createStudentAward({ commit }, params) {
      try {
        const response = await axios.post('uni/studentAwards', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateStudentAward({ commit }, params) {
      try {
        const response = await axios.put(`uni/studentAwards/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteStudentAward({ commit }, id) {
      try {
        const response = await axios.delete(`uni/studentAwards/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
