import { STATUSES } from '@/const/status'
import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    statuses: STATUSES,
    credentialProcessTypes: [],
    totalRows: 0,
    credentialFilesRequire: [],
    credentialStorage: [],
  },
  getters: {
    statuses: state => state.statuses,
    credentialProcessTypes: state => state.credentialProcessTypes,
    totalRows: state => state.totalRows,
    credentialFilesRequire: state => state.credentialFilesRequire,
    credentialStorage: state => state.credentialStorage,
  },
  mutations: {
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
    SET_TOTAL_ROWS: (state, total) => {
      state.totalRows = total
    },
    SET_CREDENTIAL_PROCESS_TYPES: (state, credentialProcessTypes) => {
      state.credentialProcessTypes = credentialProcessTypes
    },
    SET_CREDENTIAL_FILES_REQUIRE: (state, credentialFilesRequire) => {
      state.credentialFilesRequire = credentialFilesRequire
    },
    SET_CREDENTIAL_STORAGE: (state, credentialStorage) => {
      state.credentialStorage = credentialStorage
    },
  },
  actions: {
    async readCredentialProcessTypes({ commit }, { id, params }) {
      try {
        const response = await axios.get(`certificates/credential_process_types/${id}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_CREDENTIAL_PROCESS_TYPES', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async readCredentialFileRequire({ commit }, { id }) {
      try {
        const response = await axios.get(`certificates/credential_file_require_process?idCredentialProcessType=${id}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_CREDENTIAL_FILES_REQUIRE', records)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async uploadSample({ commit }, params) {
      try {
        const response = await axios.post('certificates/certificate-blanks/uploadSample', params)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
        return []
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getPathFileSample({ commit }, params) {
      try {
        const response = await axios.get('certificates/certificate-blanks/getFileMinioByPathFile', {params})
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
        return []
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async createCredentialProcessRequest({ commit }, params) {
      try {
        const response = await axios.post('certificates/credential_process_requests', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async readStudentsCredentialStorage({ commit }, params) {
      try {
        const response = await axios.get('certificates/credentials-storage', {params})
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_CREDENTIAL_STORAGE', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateStatusProcess({ commit }, {id, params}) {
      try {
        const response = await axios.put(`certificates/credential_process_requests/${id}/status?status=${params.status}`)
        const { data } = response
        return { isSuccessfulReq: data.code === ApiCode.SUCCESS, messageReq: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateMessageProcess({ commit }, {id, message}) {
      try {
        const response = await axios.put(`certificates/credential_process_requests/${id}/message`, message, {
          headers: {
            'Content-Type': 'text/plain',
          },
        })
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
