import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    members: [],
    memberTotalRows: 0,
    researches: [],
    researchTotalRows: 0,
  },
  getters: {
    members: state => state.members,
    memberTotalRows: state => state.memberTotalRows,
    researches: state => state.researches,
    researchTotalRows: state => state.researchTotalRows,
  },
  mutations: {
    SET_MEMBERS: (state, data) => { state.members = data },
    SET_MEMBER_TOTAL_ROWS: (state, data) => { state.memberTotalRows = data },
    SET_RESEARCHES: (state, data) => { state.researches = data },
    SET_RESEARCH_TOTAL_ROWS: (state, data) => { state.researchTotalRows = data },
  },
  actions: {
    async getMembersByOrganizationId({ commit }, params) {
      try {
        const response = await axios.get('uni/researchMembers/getByOrganizationId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_MEMBERS', records)
          commit('SET_MEMBER_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getResearchesByMemberId({ commit }, { id, params }) {
      try {
        const response = await axios.get(`uni/researchMembers/${id}/researches`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_RESEARCHES', records)
          commit('SET_RESEARCH_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
