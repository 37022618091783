import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    dataArr: [],
    total: 0,
  },
  getters: {
    dataArr: state => state.dataArr,
    total: state => state.total,
  },
  mutations: {
    SET_DATA: (state, dataArr) => { state.dataArr = dataArr },
    SET_TOTAL: (state, total) => { state.total = total },
  },

  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/fact_research_roles', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_DATA', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`uni/fact_research_roles/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async saveData({ commit }, body) {
      try {
        const response = await axios.post('uni/fact_research_roles', body)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getRolesByType({ commit }, params) {
      try {
        const response = await axios.get('uni/fact_research_roles/getAllByType', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DATA', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
