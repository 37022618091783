import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.configStudentCode,
})
export default {
  namespaced: true,
  state: {
      dataLists: [],
  },
  getters: {
    dataLists: state => state.dataLists,
  },
  mutations: {
    SET_LISTS: (state, records) => {
      state.dataLists = records
    },
  },

  actions: {
    async getData({ commit }) {
      try {
        const response = await axios.get(baseAttr.basePath)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const records = data.data
          commit('SET_LISTS', records )
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async updateData({ commit }, params) {
      try {
        const response = await axios.post(`${baseAttr.basePath}`, {params})
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

  },
}
