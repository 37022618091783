import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    factDisabilities: [],
    dataSources: [],
    totalRows: 0,
    maxOrderNo: 0,
    statuses: STATUSES,
    resourceName: {
      fullName: 'Loại khuyết tật',
      shortName: 'Loại KT',
    },
  },
  getters: {
    factDisabilities: state => state.factDisabilities,
    dataSources: state => state.dataSources,
    totalRows: state => state.totalRows,
    resourceName: state => state.resourceName,
    maxOrderNo: state => state.maxOrderNo,
    statuses: state => state.statuses,
    type: state => state.type,
  },
  mutations: {
    SET_FACT_DISABILITIES: (state, data) => {
      state.factDisabilities = data
    },
    SET_DATA_SOURCES: (state, data) => { state.dataSources = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => { state.maxOrderNo = maxOrderNo },
  },
  actions: {
    async getAll({ commit }) {
      try {
        const response = await axios.get('categories/fact_disabilities/allActive')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_FACT_DISABILITIES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDataSources({ commit }, params) {
      try {
        const response = await axios.get('categories/fact_disabilities', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createDataSource({ commit }, params) {
      try {
        const response = await axios.post('categories/fact_disabilities', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateDataSource({ commit }, params) {
      try {
        const response = await axios.put(`categories/fact_disabilities/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async deleteDataSource({ commit }, id) {
      try {
        const response = await axios.delete(`categories/fact_disabilities/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMaxOrderNo({ commit }, params) {
      try {
        const response = await axios.get('categories/fact_disabilities/max/order-no', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { maxOrderNo } = data.data
          commit('SET_MAX_ORDER_NO', maxOrderNo)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
