import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    students: [],
    trainingSystems: [],
    courses: [],
    allStudents: [],
  },
  getters: {
    students: state => state.students,
    trainingSystems: state => state.trainingSystems,
    courses: state => state.courses,
    allStudents: state => state.allStudents,
  },
  mutations: {
    SET_STUDENTS: (state, data) => { state.students = data },
    SET_TRAINING_SYSTEMS: (state, data) => { state.trainingSystems = data },
    SET_COURSES: (state, data) => { state.courses = data },
    SET_ALL_STUDENTS: (state, data) => { state.allStudents = data },
  },
  actions: {
    async createResearchStudentMember({ commit }, params) {
      try {
        const response = await axios.post('uni/researchStudentMembers', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getResearchStudentMembers({ commit }, params) {
      try {
        const response = await axios.get('uni/researchStudentMembers', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          commit('SET_STUDENTS', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async deleteResearchStudentMember({ commit }, id) {
      try {
        const response = await axios.delete(`uni/researchStudentMembers/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getTrainingSystemsByOrganizationId({ commit }, params) {
      try {
        const response = await axios.get('uni/trainingSystems/getByOrganizationId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TRAINING_SYSTEMS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCoursesByTrainingSystemId({ commit }, params) {
      try {
        const response = await axios.get('uni/courses/getByTrainingSystemId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_COURSES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudentsByCourse({ commit }, params) {
      try {
        const response = await axios.get('uni/students/getStudentsByCourse', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          commit('SET_ALL_STUDENTS', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
