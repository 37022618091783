import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    documents: [],
    total: 0,
  },
  getters: {
    documents: state => state.documents,
    total: state => state.total,
  },
  mutations: {
    SET_DOCUMENTS: (state, documents) => { state.documents = documents },
    SET_TOTAL: (state, total) => { state.total = total },
  },

  actions: {
    async getDocuments({ commit }, params) {
      try {
        const response = await axios.get('uni/documents', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_DOCUMENTS', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async deleteDocument({ commit }, id) {
      try {
        const response = await axios.delete(`uni/documents/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async saveDocument({ commit }, body) {
      try {
        const response = await axios.post('uni/documents', body)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async uploadDocument({ commit }, params) {
      try {
        const response = await axios.post('uni/documents/uploadDocument', params)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
        return []
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

  },
}
