import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    buildings: [],
    total: 0,
  },
  getters: {
    buildings: state => state.buildings,
    total: state => state.total,
  },
  mutations: {
    SET_BUILDINGS: (state, buildings) => { state.buildings = buildings },
    SET_TOTAL: (state, total) => { state.total = total },
  },
  actions: {
    async getBuildings({ commit }, params) {
      try {
        const response = await axios.get('uni/buildings', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_BUILDINGS', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async createBuilding({ commit }, dataObj) {
      try {
        const response = await axios.post('uni/buildings', dataObj)
        const { data } = response
        return data.code === ApiCode.SUCCESS
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async deleteBuilding({ commit }, id) {
      try {
        const response = await axios.delete(`uni/buildings/${id}`)
        const { data } = response
        return data.code === ApiCode.SUCCESS
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getByOrganizationId({ commit }) {
      try {
        const response = await axios.get('uni/buildings/getByOrganizationId')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_BUILDINGS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
