import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    resources: [],
    totalRows: 0,
    menus: [],
  },
  getters: {
    resources: state => state.resources,
    totalRows: state => state.totalRows,
    menus: state => state.menus,
  },
  mutations: {
    SET_RESOURCES: (state, { total, records }) => {
      state.totalRows = total
      state.resources = records
    },
    SET_BEGIN: (state, menus) => {
      state.menus = menus
    },
  },
  actions: {
    async begin({ commit }) {
      try {
        const response = await axios.get('auth/resources/begin')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { menus } = data.data
          commit('SET_BEGIN', menus)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getResources({ commit }, params) {
      try {
        const response = await axios.post('auth/resources/list', params)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_RESOURCES', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createResource({ commit }, params) {
      try {
        const response = await axios.post('auth/resources', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateResource({ commit }, params) {
      try {
        const response = await axios.put(`auth/resources/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteResource({ commit }, id) {
      try {
        const response = await axios.delete(`auth/resources/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
