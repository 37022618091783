import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    trainingSystems: [],
    courses: [],
    semesters: [],
    subjects: [],
    students: [],
  },
  getters: {
    trainingSystems: state => state.trainingSystems,
    courses: state => state.courses,
    semesters: state => state.semesters,
    subjects: state => state.subjects,
    students: state => state.students,
  },
  mutations: {
    SET_TRAINING_SYSTEMS: (state, data) => { state.trainingSystems = data },
    SET_COURSES: (state, data) => { state.courses = data },
    SET_SEMESTERS: (state, data) => { state.semesters = data },
    SET_SUBJECTS: (state, data) => { state.subjects = data },
    SET_STUDENTS: (state, data) => { state.students = data },
  },
  actions: {
    async getTrainingSystemsByOrganizationId({ commit }, params) {
      try {
        const response = await axios.get('uni/trainingSystems/getByOrganizationId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TRAINING_SYSTEMS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCoursesByTrainingSystemId({ commit }, params) {
      try {
        const response = await axios.get('uni/courses/getByTrainingSystemId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_COURSES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getSemestersByCourseId({ commit }, params) {
      try {
        const response = await axios.get('uni/semesters/getByCourseId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_SEMESTERS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getSubjectsByCourseSemesterId({ commit }, params) {
      try {
        const response = await axios.get('uni/subjects/getByCourseSemesterId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_SUBJECTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudentsBeRepeated({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/getStudentsBeRepeated', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_STUDENTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadPdf({ commit }, params) {
      try {
        const response = await axios({
          method: 'get',
          url: 'uni/reports/reportStudentsBeRepeatedPdf',
          responseType: 'blob',
          params,
        })
        return response.data
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
