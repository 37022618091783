import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    allDepartments: [],
    allYears: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    allDepartments: state => state.allDepartments,
    allYears: state => state.allYears,
  },
  mutations: {
    SET_ALL: (state, { dataDepartments, dataYears }) => {
      state.allDepartments = dataDepartments
      state.allYears = dataYears
    },
    SET_DATA: (state, { data }) => {
      state.dataLists = data
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportTeacherSpecializationAssignment', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_DATA', {
            data: response.data.data,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async begin({ commit }, params) {
      try {
        const rsYears = await axios.get('categories/factYears/all',{ params })
        const rsDepartments = await axios.get('uni/departments', { params })
        if (rsYears.data.code === ApiCode.SUCCESS && rsDepartments.data.code === ApiCode.SUCCESS) {
          commit('SET_ALL', {
            dataDepartments: rsDepartments.data.data.records,
            dataYears: rsYears.data.data,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
