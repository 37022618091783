import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    documents: [],
    totalRows: 0,
    url: '',
  },
  getters: {
    documents: state => state.documents,
    totalRows: state => state.totalRows,
    url: state => state.url,
  },
  mutations: {
    SET_DOCUMENTS: (state, data) => { state.documents = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
    SET_URL: (state, data) => { state.url = data },
  },
  actions: {
    async getTeacherDocuments({ commit }, params) {
      try {
        const response = await axios.get('uni/teacherDocuments', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_DOCUMENTS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createTeacherDocuments({ commit }, params) {
      try {
        const response = await axios.post('uni/teacherDocuments', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async downloadTeacherDocument({ commit }, params) {
      try {
        const response = await axios.get('uni/teacherDocuments/download', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_URL', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async deleteTeacherDocument({ commit }, id) {
      try {
        const response = await axios.delete(`uni/teacherDocuments/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async uploadDocument({ commit }, params) {
      try {
        const response = await axios.post('uni/teacherDocuments/uploadDocument', params)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
        return []
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async saveTeacherDocument({ commit }, body) {
      try {
        const response = await axios.post('uni/teacherDocuments/saveTeacherDocument', body)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
