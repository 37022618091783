import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export const baseAttr = Object.freeze({
    basePath: ApiExtRoutes.setOfCriteriaObjects,
})

export default {
    namespaced: true,
    state: {
        setOfCriteriaObjects: [],
        totalRows: 0,
        statuses: [],
        setOfCriterias: [],
    },
    getters: {
        setOfCriteriaObjects: state => state.setOfCriteriaObjects,
        totalRows: state => state.totalRows,
        statuses: state => state.statuses,
        setOfCriterias: state => state.setOfCriterias,
    },
    mutations: {
        SET_OF_CRITERIA_OBJECTS: (state, records) => {
            state.setOfCriteriaObjects = records
        },
        SET_TOTAL_ROWS: (state, total) => {
            state.totalRows = total
        },
        SET_STATUSES: (state, statuses) => {
            state.statuses = statuses
        },
        SET_OF_CRITERIAS: (state, setOfCriterias) => {
            state.setOfCriterias = setOfCriterias
        },
    },
    actions: {
        async getSetOfCriteriaObjects({ commit }, params) {
            try {
                const response = await axios.get(`surveys/employeeEvaluate/setOfCriteriaObjects`, { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const {total, records} = data.data
                    commit('SET_OF_CRITERIA_OBJECTS', records)
                    commit('SET_TOTAL_ROWS', total)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
    },
}
