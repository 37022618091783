import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    documents: [],
    url: '',
  },
  getters: {
    documents: state => state.documents,
    url: state => state.url,
  },
  mutations: {
    SET_DOCUMENTS: (state, data) => { state.documents = data },
    SET_URL: (state, data) => { state.url = data },
  },
  actions: {
    async getCreditClassDocuments({ commit }, params) {
      try {
        const response = await axios.get('uni/creditClassMedias', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DOCUMENTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createCreditClassDocuments({ commit }, params) {
      try {
        const response = await axios.post('uni/creditClassMedias', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async downloadCreditClassDocument({ commit }, params) {
      try {
        const response = await axios.get('uni/creditClassMedias/download', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_URL', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async deleteCreditClassDocument({ commit }, id) {
      try {
        const response = await axios.delete(`uni/creditClassMedias/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
